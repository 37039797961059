import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './css/global.css';
import './css/prism.css';
import 'tailwindcss/tailwind.css';
// import '@splidejs/react-splide/css';


import Success from './components/Success';
import Canceled from './components/Canceled';
import Cards from './components/Cards';

import './css/normalize.css';
import './css/global.css';

function App() {
  React.useEffect(() => {
    document.title = 'Permit.io Payments';
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/success.html" element={<Success />} />
        <Route path="/canceled.html" element={<Canceled />} />
        <Route path="/" element={<Cards />} />
        <Route path="/payment" element={<Cards />} />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
